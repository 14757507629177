import HasPlans from '../mixins/HasPlans';

const PlanType = () => import('@/modules/service-plan/components/PlanType.vue');

export default {
  name: 'PlanList',

  mixins: [HasPlans],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      default: undefined,
    },
  },

  components: {
    PlanType,
  },

  computed: {
    items() {
      return this.planListItems;
    },
    //TODO: Consider change to selected plan level instead of whole object
    selectedPlanIndex: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
